import React from 'react'
import BannerHeader from '../components/BannerHeader'
import CourseItem from '../components/CourseItem'
import Flex from '../components/Flex'
import MainInner from '../components/MainInner'
import InstructorIntro from '../components/InstructorIntro'
import Seo from '../components/Seo'
import useAllCourses from '../hooks/useAllCourses'
import shevy, { bs } from '../shevy'
import { mq } from '../utils'
import { Testimonials } from '../components/Testimonials'

export default function Home() {
  return (
    <>
      <Seo title="Home" />

      <BannerHeader>
        <div
          css={{
            fontFamily: 'var(--fonts-catamaran)',
            fontSize: shevy.h2.fontSize,
            fontWeight: 'bold',
            textAlign: 'center',
            textShadow: '0 0 8px rgba(0, 0, 0, .25)',

            [mq.charlie]: {
              fontSize: shevy.h1.fontSize,
            },
          }}
        >
          Learn software engineering &&nbsp;web&nbsp;development
        </div>
      </BannerHeader>

      <MainInner>
        <CoursesSection />
        <hr />
        <InstructorSection />
        <hr />
        <Testimonials />
      </MainInner>
    </>
  )
}

function CoursesSection() {
  const allCourses = useAllCourses()

  return (
    <section>
      <h2>Available Courses</h2>
      <ul
        css={{
          display: 'grid',
          alignItems: 'stretch',
          gap: bs(),
          listStyle: 'none',

          [mq.bravo]: {
            gridTemplateColumns: '1fr 1fr',
          },
        }}
      >
        {allCourses.map(course => (
          <CourseItem key={course.id} course={course} />
        ))}
        <li
          css={{
            fontFamily: 'var(--fonts-catamaran)',
            fontSize: '2rem',
            fontWeight: 'bold',
          }}
        >
          <Flex
            align="center"
            justify="center"
            styles={{ height: '100%', textAlign: 'center' }}
          >
            <div>More courses coming&nbsp;soon!</div>
          </Flex>
        </li>
      </ul>
    </section>
  )
}

function InstructorSection() {
  return (
    <section>
      <h3>Meet your instructor</h3>
      <InstructorIntro />
    </section>
  )
}
