import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import shevy, { bs } from '../shevy'
import { mq } from '../utils'

const query = graphql`
  query {
    allImageSharp(filter: { original: { src: { regex: "/kyle400/" } } }) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
  }
`

export default function InstructorIntro() {
  const { allImageSharp } = useStaticQuery(query)
  const imgSrc = allImageSharp.edges[0].node.original.src

  return (
    <div
      css={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: bs(),

        [mq.alpha]: {
          flexDirection: 'row',
        },
      }}
    >
      <img
        css={{
          borderRadius: bs(),
          display: 'block',
          width: '100%',
          maxWidth: 200,
          height: 'auto',
        }}
        src={imgSrc}
        alt="Kyle Shevlin's face, which is mostly a beard with eyes"
      />
      <div>
        <p css={{ fontSize: shevy.h4.fontSize }}>
          Hello!{' '}
          <span aria-label="hand wave" role="img">
            👋🏻
          </span>
        </p>
        <p css={{ marginBottom: 0 }}>
          My name is Kyle Shevlin. I'm a software engineer who loves JavaScript,
          React, frontend web development, and more. I have been making online
          courses for several years now and I'm excited to share my personal
          course platform with you. Enjoy!
        </p>
      </div>
    </div>
  )
}
