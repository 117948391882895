import React from 'react'
import { useSupabaseContext } from '../contexts/SupabaseContext'
import { useBool } from '@kyleshevlin/use-common'
import { Row, Stack } from '@kyleshevlin/layout'
import Beard from './icons/Beard'
import { bs } from '../shevy'
import { Label } from './Label'

const COURSE_IDS_TO_LABELS = {
  'array-reduce': 'Array.reduce',
}

export function Testimonials() {
  return (
    <section>
      <h3>Testimonials</h3>
      <Inner />
    </section>
  )
}

function Inner() {
  const supabase = useSupabaseContext()
  const [loading, setLoading] = useBool()
  const [error, setError] = React.useState()
  const [feedback, setFeedback] = React.useState()

  React.useEffect(() => {
    if (!supabase) return

    async function getFeedback() {
      setLoading.on()

      const { data, error } = await supabase
        .from('feedback')
        .select('id, course_id, rating, statement')
        .eq('approved', true)
        .eq('publicEndorsementPermission', true)
        .order('created_at', { ascending: false })
        .limit(10)

      if (error) {
        setError(error)
      }

      if (data) {
        setFeedback(data)
      }

      setLoading.off()
    }

    getFeedback()
  }, [setLoading, supabase])

  if (loading) {
    return <p>Loading...</p>
  }

  if (error) {
    if (process.env.NODE === 'development') {
      console.error(error)
    }

    return <p>There was an error fetching the testimonials.</p>
  }

  if (!feedback?.length) {
    return (
      <p>
        One day, your kind words could be here, telling others how much you
        appreciated the course and the instructor.{' '}
        <span aria-label="grin" role="img">
          😁
        </span>{' '}
        When you're done with the course, fill out the feedback form and share
        your thoughts.
      </p>
    )
  }

  return (
    <Stack gap={1.5}>
      <div>Enjoy testimonials from satisfied students.</div>

      <Row gap={1.5} wrap="wrap">
        {feedback.map(item => {
          const courseLabel = COURSE_IDS_TO_LABELS[item.course_id]

          return (
            <div
              css={{
                border: '2px solid var(--colors-accent)',
                borderRadius: 4,
                boxShadow: '4px 4px var(--colors-accent)',
                fontFamily: 'var(--fonts-catamaran)',
                padding: bs(),
                maxWidth: '65ch',
              }}
              key={item.id}
            >
              <Stack gap={1}>
                <Stack gap={0.25}>
                  {courseLabel && (
                    <Row align="baseline" gap={0.5}>
                      <Label>Course:</Label>
                      <span>{courseLabel}</span>
                    </Row>
                  )}

                  <Row align="baseline" gap={0.5}>
                    <Label>Rating:</Label>

                    <Row gap={0.25}>
                      {Array(item.rating)
                        .fill()
                        .map((_, idx) => (
                          <Beard
                            fill="var(--colors-accent)"
                            key={idx}
                            width={20}
                          />
                        ))}
                    </Row>

                    <span css={{ fontSize: '85%' }}>({item.rating}/5)</span>
                  </Row>
                </Stack>

                <div css={{ fontStyle: 'italic' }}>{item.statement}</div>
              </Stack>
            </div>
          )
        })}
      </Row>

      <div>Be sure to leave your own feedback after completing a course!</div>
    </Stack>
  )
}
